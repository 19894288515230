//核心企业
// 引入封装好的axios
import axios ,{ apiUrl } from "./request";
//定义跨域代理路由

// const api = process.env.NODE_ENV == "development" ? '/devapi' : '/api'
// const api = process.env.NODE_ENV == "development" ? '/devapi' : 'https://www.o2c-scf.com/api'
const api = process.env.NODE_ENV == "development" ? '/devapi' : apiUrl()

//我的供应商-添加供应商-获取供应商编号
export const getSupplerNum = (params)=>{
    return axios.get(api + '/core/getSupplerNum')
}
//添加/编辑供应商
export const editSupplier = (params)=>{
    return axios.post(api + '/core/editSupplier' , params)
}
//查询我的供应商列表
export const getSupplierList = (params)=>{
    return axios.post(api + '/core/getSupplierList?' + params)
}
//查询客户发票列表
export const getCoreInvoiceList = (params)=>{
    return axios.post(api + '/invoice/getInvoiceList?' + params)
}
//查询供应商发票列表
export const getSupplierInvoiceList = (params)=>{
    return axios.post(api + '/core/getSupplierInvoiceList?' + params)
}
//发票确认
export const confirmInvVerification = (params)=>{
    return axios.post(api + '/core/invoiceVerification' , params)
}
//协助还款
export const helpRepayment = (params)=>{
    return axios.post(api + '/core/helpRepayment' , params)
}
//查询供应商名称
// export const getSupplierName = (params)=>{
//     return axios.post(api + '/core/getSupplierName?' + params)
// }
//查询我的客户列表
export const getClientList = (params)=>{
    return axios.post(api + '/supplier/getMyPartnerList?'+ params)
}
//获取客户编号
export const getCustomerNum = (params)=>{
    return axios.get(api + '/core/getCustomerNum')
}
//编辑客户信息
export const editCustomer = (params)=>{
    return axios.post(api + '/supplier/mypartner', params)
}
//添加/编辑发票
export const editCoreInvoice = (params)=>{
    return axios.post(api + '/invoice/editInvoice', params)
}
//1.核心企业统计
export const coreTotal = ()=>{
    return axios.post(api + '/statistic/core')
}
//客户
//1.核心企业客户交易金额占比列表
export const clientAmountRatio = ()=>{
    return axios.post(api + '/statistic/core/clientAmountRatio')
}
//2.核心企业客户交易金额列表
export const clientDealAmount = ()=>{
    return axios.post(api + '/statistic/core/clientDealAmount')
}
//3.核心企业客户交易数量列表
export const clientDealNum = ()=>{
    return axios.post(api + '/statistic/core/clientDealNum')
}
//4.核心企业客户交易地区列表
export const clientDealRegion = ()=>{
    return axios.post(api + '/statistic/core/clientDealRegion')
}
//供应商
//1.核心企业供应商交易金额占比列表
export const supplierAmountRatio = ()=>{
    return axios.post(api + '/statistic/core/supplierAmountRatio')
}
//2.核心企业供应商交易金额列表
export const supplierDealAmount = ()=>{
    return axios.post(api + '/statistic/core/supplierDealAmount')
}
//3.核心企业供应商交易数量列表
export const supplierDealNum = ()=>{
    return axios.post(api + '/statistic/core/supplierDealNum')
}
//4.核心企业供应商交易地区列表
export const supplierDealRegion = ()=>{
    return axios.post(api + '/statistic/core/supplierDealRegion')
}
//5.核心企业供应商+客户红黑榜
export const getAllRatingList = ()=>{
    return axios.post(api + '/supplier/getRatingList')
}

//我的融资列表
export const getMyFinancingList = (params)=>{
    return axios.post(api + '/core/getMyFinancingList?' + params)
}

//我的投资列表
export const getMyInvestmentList = (params)=>{
    return axios.post(api + '/core/getMyInvestmentList?' + params)
}

//可选择邀约对象
export const getSelectInvation = (params)=>{
    return axios.post(api + '/supplier/getSelectInvation?' + params)
}

//我的获客
export const getRecommendList = (params)=>{
    return axios.post(api + '/recommend/getRecommendList?' + params)
}
//收藏
export const setCollection = (params)=>{
    return axios.post(api + '/recommend/setCollection?' + params)
}
//获客
export const getPreList = (params)=>{
    return axios.post(api + '/recommend/getPreferenceList?' + params)
}

//查询发票表格
export const getInvoiceListByFN = (params) => {
    return axios.post(api + '/invoice/getInvoiceListByFN?' + params)
}
